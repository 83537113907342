export default {
  ca: 'catalan',
  de: 'deutsch',
  en: 'english',
  'en-gb': 'english (GB)',
  es: 'español',
  fr: 'français',
  it: 'italiano',
  nb: 'norsk',
};
